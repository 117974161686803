import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/syeda/corvette/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Is a Corvette a muscle or sports car?`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "945px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a60d6e7f77cccbb15792fe2e25c41070/0ede0/chevrolet-corvette-zr1.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABZcajU0CMP//EABoQAAIDAQEAAAAAAAAAAAAAAAECAAMREiH/2gAIAQEAAQUCtsqLBws72aQU9af/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAABBQEAAAAAAAAAAAAAAAAAAQIQETFR/9oACAEBAAY/AuqW7Y0W4//EABsQAAMAAwEBAAAAAAAAAAAAAAABESExQVFh/9oACAEBAAE/IUqJqUtiO6/S35EdlhcRqWRhMin/2gAMAwEAAgADAAAAEFcv/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8QZ//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/EBJ//8QAHhABAAICAQUAAAAAAAAAAAAAAQARITFhQVGBkaH/2gAIAQEAAT8QJHJlB3s2/JZ0sFlLU9DmDG/zYYPFQVWL2MahTY5eJY6z/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Chevrolet Corvette ZR1",
            "title": "Chevrolet Corvette ZR1",
            "src": "/static/a60d6e7f77cccbb15792fe2e25c41070/0ede0/chevrolet-corvette-zr1.jpg",
            "srcSet": ["/static/a60d6e7f77cccbb15792fe2e25c41070/f93b5/chevrolet-corvette-zr1.jpg 300w", "/static/a60d6e7f77cccbb15792fe2e25c41070/b4294/chevrolet-corvette-zr1.jpg 600w", "/static/a60d6e7f77cccbb15792fe2e25c41070/0ede0/chevrolet-corvette-zr1.jpg 945w"],
            "sizes": "(max-width: 945px) 100vw, 945px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`When it comes to horsepower, the Corvette is not only one of the fastest cars on the market, but it also has the best handling of any car in its class. While its predecessor, the C1, was known as the "stinking lion" of the American sports car scene, it wasn't quite as powerful as its successor. The original, pill-shaped Corvette was also a pretty slow car and wasn't dynamic.`}</p>
    <p>{`Although Corvette is a classic muscle car, its performance isn't quite as renowned as the sports cars it inspired. Compared to some Italian supercars, the top-of-the-range C6 ZR1 has more horsepower and is the most luxurious of all the muscle cars. In fact, it was so powerful that Prince adored it that the name "Corvette" has become synonymous with muscle cars. The C8 is the eighth generation of Corvettes and is considered a true sports car. While the Corvette isn't as fast as the Corvette C6, it is one of the most powerful and expensive muscle cars in the world.`}</p>
    <p>{`While the Corvette has been labeled a sports car by some enthusiasts, the car's popularity isn't just a matter of personal preference. Its popularity continues to grow as new generations join the muscle-car arena. In the US, it isn't as easy to find a Corvette that falls into the "muscle" category.`}</p>
    <p>{`As a muscle car, the Corvette has a powerful engine, which makes it a muscle-car. But the Corvette isn't a sports car. It is a luxury sports car, and it has a lower price than the average supercar. Whether you consider it a sport or a muscle-car, you'll be pleased with the way it drives and handles. In fact, the top-of-the-line Corvette, the C6 ZR1, is the only true luxury muscle car. Unlike other muscle cars, it has been the undisputed champion of the American sports car scene for almost 50 years.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      